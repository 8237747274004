import './App.css';
import { Provider } from "react-redux";
import store from './store/store';
import SpiriumPaths from './router/paths';

function App() {
  return (
    <Provider store={store}>
      <SpiriumPaths/>
    </Provider>
  );
}

export default App;
