import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import { AdminData } from '../../store/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import axios from 'axios';
import { Snackbar } from '@mui/material';

export default function AdminRecoveryFormDialog() {
    const emailInitialState = {
        email: ''
    }

    const [open, setOpen] = React.useState(false);
    const [openSuccessToast, setOpenSuccessToast] = React.useState(false);
    const [openErrorToast, setOpenErrorToast] = React.useState(false);
    const [newEmail, setNewEmail] = React.useState(emailInitialState);
    const [alert, setAlert] = React.useState<any>('');
    const user: AdminData = useSelector(
        (state: RootState) => state.adminData.value
    );

    const handleClickOpen = () => {
        setOpen(true);
        setOpenErrorToast(false);
        setOpenSuccessToast(false);
    };

    const handleClose = () => {
        setOpen(false);
        setNewEmail(emailInitialState);
    };

    const handleToastClose = () => {
        setOpenErrorToast(false);
        setOpenSuccessToast(false);
    }

    const sendConfirmationEmail = () => {
        const validateEmailRegex = /^\S+@\S+\.\S+$/;
        if (validateEmailRegex.test(newEmail.email)) {
            axios({
                method: 'post',
                url: `${user.url}/admin/initPasswordRecovery?` + new URLSearchParams({ email: newEmail.email }).toString(),
                headers: { Authorization: `Bearer ${user.token}` },
                timeout: 8000
            })
                .then(response => {
                    if (response.status === 200) { 
                        console.log("OK")
                        handleClose();
                        setOpenSuccessToast(true);
                        setAlert(''); 
                    } else {setOpenErrorToast(true)}
                })
                .catch(error => { console.log(error); setOpenErrorToast(true)});

        } else {
            setAlert(<Alert severity="error">Wpisany adres e-mail jet niepoprawny!</Alert>)
        }
    }

    return (
        <div>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={openSuccessToast} autoHideDuration={6000} onClose={handleToastClose}>
                <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
                    Pomyślnie wysłano link.
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={openErrorToast} autoHideDuration={6000} onClose={handleToastClose}>
                <Alert onClose={handleToastClose} severity="error" sx={{ width: '100%' }}>
                    Wystąpił błąd. Spróbuj ponownie za chwilę.
                </Alert>
            </Snackbar>
            <Link href="#" onClick={handleClickOpen} variant="body2" underline="hover">
                Zapomniałeś hasła?
            </Link>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth='sm'>
                <DialogTitle>Wypełnij adres e-mail, na który prześlemy link do formularza zmiany hasła.</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="E-mail"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={newEmail.email}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setNewEmail({ ...newEmail, email: event.target.value });
                            setAlert('');
                        }}
                    />
                    {alert}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        sendConfirmationEmail();
                    }}>Dodaj</Button>
                    <Button onClick={handleClose}>Anuluj</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}