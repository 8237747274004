import Dashboard from '../components/Dashboard/Dashboard';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LogIn from '../components/LogIn/LogIn';
import { UsersTab, AdminsTab, DeviceTab, NoneTab, SettingsTab } from '../components/RouterOutlet';
import PasswordRecovery from '../components/PasswordRecovery/PasswordRecovery';
import NewAdminForm from '../components/NewAdminForm/NewAdminForm';
import { AdminData } from '../store/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { useEffect } from 'react';
import axios from 'axios';
import { setAdminData } from '../store/slices/adminDataSlice';

const ProtectedRoute = (props: { user: any, children: any }) => {
    if (!props.user.token) {
        return <Navigate to="/" replace />;
    }
    return props.children;
};

function SpiriumPaths() {
    const user: AdminData = useSelector(
        (state: RootState) => state.adminData.value
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if(user.token){
            console.log(user.id);
            
            setTimeout(() => {
                axios({
                    method: 'post',
                    url: `http://localhost:7224/auth/refreshToken?` + new URLSearchParams({ id: user.id }).toString(),
                    headers: { Authorization: `Bearer ${user.token}`, 'refresh-token': user.refreshToken},
                    timeout: 8000
                }).then(response => {
                        if(response.status === 200){
                            dispatch(setAdminData({...user,token: response.headers['x-access-token'], refreshToken: response.headers['refresh-token']}));
                        }}).catch(error => { console.log(error + "TOKEN ERROR") });
            }, 60000 * 0.5 * 0.5) //12 min
        }
    }, [user.token])

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={
                    <LogIn />}
                />
                <Route path="/passwordRecovery" element={
                    <PasswordRecovery />}
                />
                <Route path="/newAdmin" element={
                    <NewAdminForm />}
                />
                <Route path="/home" element={<div className="App"><ProtectedRoute user={user}><Dashboard /></ProtectedRoute></div>}>
                    <Route path="users" element={<ProtectedRoute user={user}><UsersTab /></ProtectedRoute>} />
                    <Route path="admins" element={<ProtectedRoute user={user}><AdminsTab /></ProtectedRoute>} />
                    <Route path='devices' element={<ProtectedRoute user={user}><DeviceTab /></ProtectedRoute>} />
                    <Route path='settings' element={<ProtectedRoute user={user}><SettingsTab /></ProtectedRoute>} />
                    <Route path="dummy" element={<ProtectedRoute user={user}><NoneTab /></ProtectedRoute>} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default SpiriumPaths;
