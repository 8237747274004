import { configureStore } from "@reduxjs/toolkit";
import patientsReducer from "./slices/patientsSlice";
import adminReducer from "./slices/adminDataSlice";
import devicesReducer from "./slices/devicesDataSlice";

const store = configureStore({
  reducer: {
    patients: patientsReducer,
    adminData: adminReducer,
    devices: devicesReducer
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;