export type Patient = {
    creationTime: string
    email: string
    id: string
    lastAccessTime: string
    name: string
    surname: string
    verifiedAt: string
}

export type AdminData = {
    token: string;
    refreshToken: string;
    id: string;
    email: string;
    url?: unknown;
}

export type Admin = {
    id: string;
    email: string;
}

export type Device = {
    id: string;
    serialNumber: string;
    name: string;
}

export interface PatientsInitialState {
    value: Patient[];
}

export interface AdminDataInitialState {
    value: AdminData;
    admins: Admin[];
}

export interface DevicesInitialState {
    value: Device[];
    issued: Device[];
}

const UpdatePatientsAction: string = "Patients";
const UpdateAdminDataAction: string = "AdminData";
const UpdateDevicesAction: string = "Devices";

export { UpdatePatientsAction, UpdateAdminDataAction, UpdateDevicesAction };
