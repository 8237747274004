import AutoEmailConfig from "./AutoEmailConfig";
import React, { useState } from "react";
import { Paper, IconButton, Collapse, Box, Typography } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

const Settings = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const paperHeight = isCollapsed ? 70 : 320;

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        height: paperHeight,
        transition: "height 0.3s ease",
        overflow: "hidden",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <IconButton onClick={toggleCollapse}>
          {isCollapsed ? <ExpandMore /> : <ExpandLess />}
        </IconButton>
        <Typography>Ustawienia powiadomień poczty elektronicznej</Typography>
      </Box>
      <Collapse in={!isCollapsed}>
        <AutoEmailConfig />
      </Collapse>
    </Paper>
  );
};

export default Settings;
