import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { AdminData } from '../../../../store/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import axios from 'axios';
import { setAllDevices } from '../../../../store/slices/devicesDataSlice';
import { Box } from '@mui/material';

export default function DeviceFormDialog() {
    const adminInitialState = {
        serialNumber: ''
    }

    const [open, setOpen] = React.useState(false);
    const [newDevice, setNewDevice] = React.useState(adminInitialState);
    const [alert, setAlert] = React.useState<any>('');
    const user: AdminData = useSelector(
        (state: RootState) => state.adminData.value
    );
    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setNewDevice(adminInitialState);
    };

    const refreshData = () => {
        axios({
            method: 'get',
            url: `${user.url}/devices/getAllDevices`,
            timeout: 8000
        })
            .then(response => {
                if(response.status === 200){
                    dispatch(setAllDevices(response.data));
                }
             
            })
            .catch(error => { console.log(error) });
    }

    const sendConfirmationEmail = () => {
        axios({
            method: 'post',
            url: `${user.url}/devices/addNewDevice?` + new URLSearchParams({ serialNumber: newDevice.serialNumber }).toString(),
            timeout: 5000
        })
            .then(response => {
                if (response.status === 200) {
                    setOpen(false);
                    refreshData();
                }
                console.log(response);
            })
            .catch(error => { console.log(error) });
        handleClose();
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: "flex-end" }}>
            <Tooltip title="Dodaj urządzenie" arrow placement='left'>
                <IconButton sx={{ mt: 2, mb: 2 }} onClick={handleClickOpen}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth='sm'>
                <DialogTitle>Nowe urządzenie</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="sn"
                        label="Numer seryjny"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={newDevice.serialNumber}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setNewDevice({ ...newDevice, serialNumber: event.target.value });
                            setAlert('');
                        }}
                    />
                    {alert}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        sendConfirmationEmail();
                    }}>Dodaj</Button>
                    <Button onClick={handleClose}>Anuluj</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}