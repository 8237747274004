import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { AdminData, Device } from '../../../../store/types';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { getValueOf } from '../../../../helpers/propValues';
import DeviceFormDialog from './DeviceDialogForm';
import DeleteDialog from './DeleteDialog';
import { setAllDevices, setAllIssuedDevices } from '../../../../store/slices/devicesDataSlice';
import axios from 'axios';
import IssuedDevice from './IssuedDevice';
import Grid from '@mui/material/Grid';

function Row(props: { row: Device }) {
    const { row } = props;

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell align="left">{row.serialNumber}</TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align='left'>
                    <IssuedDevice serialNumber={row.serialNumber}/>
                </TableCell>
                <TableCell align="right">
                    <DeleteDialog id={row.id} serialNumber={row.serialNumber} />
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function DevicesTable() {
    const devices: Device[] = useSelector(
        (state: RootState) => state.devices.value
    );
    const user: AdminData = useSelector(
        (state: RootState) => state.adminData.value
    );
    const [rows, setRows] = React.useState<Device[]>(devices);
    const [searchInput, setSearchInput] = React.useState<string>("");
    const dispatch = useDispatch();

    React.useEffect(() => {
        setRows(devices);
    }, [devices])

    React.useEffect(() => {
        axios({
            method: 'get',
            url: `${user.url}/devices/getAllDevices`,
            //headers: { Authorization: `Bearer ${user.token}` },
            timeout: 8000
        }).then(response => {
                if(response.status === 200){
                    dispatch(setAllDevices(response.data));
                }}).catch(error => { console.log(error) });

        axios({
            method: 'get',
            url: `${user.url}/devices/getAllIssuedDevices`,
            //headers: { Authorization: `Bearer ${user.token}` },
            timeout: 8000
        }).then(response => {
                if(response.status === 200){
                    dispatch(setAllIssuedDevices(response.data));
                }}).catch(error => { console.log(error) });
    }, [])

    const requestSearch = (searchedVal: string) => {
        const filteredRows = devices.filter((row) => {
            const filter = getValueOf(row, 'serialNumber');
            if (filter) {
                return filter.toLowerCase().includes(searchedVal.toLowerCase());
            }
        });
        setRows(filteredRows);
    };

    return (
        <>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    alignItems: 'flex-end',
                    flexDirection: 'column',
                    height: 700,
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                            <TextField id="input-with-sx" label="Szukaj" variant="standard"
                                value={searchInput}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    requestSearch(event.target.value);
                                    setSearchInput(event.target.value);
                                }}>
                            </TextField>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <DeviceFormDialog />
                    </Grid>
                </Grid>
                <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Numer seryjny</TableCell>
                                <TableCell align="left">Nazwa</TableCell>
                                <TableCell align='left'>Przypisanie</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <Row key={index} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>

    );
}