import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { Admin, AdminData, Patient } from '../../../../store/types';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import DeleteDialog from './DeleteDialog';
import PatientFormDialog from './AdminDialogForm';
import { getValueOf } from '../../../../helpers/propValues';
import axios from 'axios';
import { setAllAdmins } from '../../../../store/slices/adminDataSlice';
import AdminFormDialog from './AdminDialogForm';

function Row(props: { row: Admin }) {
    const { row } = props;

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell align="right">
                    <DeleteDialog id={row.id} email={row.email} />
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function UsersCollapsibleTable() {
    const admins: Admin[] = useSelector(
        (state: RootState) => state.adminData.admins
    );

    const user: AdminData = useSelector(
        (state: RootState) => state.adminData.value
    );
    
    const dispatch = useDispatch();
    
    const [rows, setRows] = React.useState<Admin[]>(admins);
    const [searchInput, setSearchInput] = React.useState<string>("");

    React.useEffect(() => {
        axios({
            method: 'get',
            url: `${user.url}/admin/getAllAdmins`,
            headers: { Authorization: `Bearer ${user.token}` },
            timeout: 8000
        })
            .then(response => {
                if(response.status === 200){
                    dispatch(setAllAdmins(response.data));
                }
             
            })
            .catch(error => { console.log(error) });
    }, [])

    React.useEffect(() => {
        setRows(admins);
    }, [admins])

    const requestSearch = (searchedVal: string) => {
        const filteredRows = admins.filter((row) => {
            const filter = getValueOf(row, 'email');
            if (filter) {
                return filter.toLowerCase().includes(searchedVal.toLowerCase());
            }
        });
        setRows(filteredRows);
    };

    return (
        <>
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    alignItems: 'flex-end',
                    flexDirection: 'column',
                    height: 700,
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                        <TextField id="input-with-sx" label="Szukaj" variant="standard"
                            value={searchInput}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                requestSearch(event.target.value);
                                setSearchInput(event.target.value);
                            }}>
                        </TextField>
                    </Box>
                    <AdminFormDialog />
                </Box>
                <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">E-mail</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <Row key={index} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>

    );
}