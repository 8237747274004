import { Device, DevicesInitialState, UpdateDevicesAction } from "../types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: DevicesInitialState = {
    value: [],
    issued: []
};

export const devicesSlice = createSlice({
    name: UpdateDevicesAction,
    initialState: initialState,
    reducers: {
        addDevice: (state, action: PayloadAction<Device>) => {
            state.value.push(action.payload)
        },
        deleteDevice: (state, action: PayloadAction<string>) => {
            state.value = state.value.filter(item => item.id !== action.payload);
        },
        setAllDevices: (state, action: PayloadAction<Device[]>) => {
            state.value = action.payload;
        },
        setAllIssuedDevices: (state, action: PayloadAction<Device[]>) => {
            state.issued = action.payload;
        }
    },
});

export const { addDevice, deleteDevice, setAllDevices, setAllIssuedDevices } = devicesSlice.actions;

export default devicesSlice.reducer;