import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
//ikony
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';

export const MainListItems = () => {
    const navigate = useNavigate();
    const toAdmins = () => {
        navigate('/home/admins');
    }
    const toUsers = () => {
        navigate('/home/users');
    }
    const toDevices = () => {
        navigate('/home/devices');
    }
    const toSettings = () => {
        navigate('/home/settings');
    }

    return (<React.Fragment>
        <ListItemButton onClick={toAdmins}>
            <ListItemIcon>
                <AdminPanelSettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Administratorzy" />
        </ListItemButton>
        <ListItemButton onClick={toUsers}>
            <ListItemIcon>
                <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText primary="Użytkownicy" />
        </ListItemButton>
        <ListItemButton onClick={toDevices}>
            <ListItemIcon>
                <AnalyticsIcon />
            </ListItemIcon>
            <ListItemText primary="Urządzenia" />
        </ListItemButton>
        <ListItemButton onClick={toSettings}>
            <ListItemIcon>
                <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Ustawienia" />
        </ListItemButton>
    </React.Fragment>)
}


