export function getValueOf(object: any, prop: string): any {
    return object[prop];
}

export function changeFromPL(str: string) {
    if(str){
        str = str.replace("ę", "e");
        str = str.replace("ó", "o");
        str = str.replace("ą", "a");
        str = str.replace("ś", "s");
        str = str.replace("ł", "l");
        str = str.replace("ż", "z");
        str = str.replace("ź", "z");
        str = str.replace("ć", "c");
        str = str.replace("ń", "n");
        str = str.toLowerCase();
        //console.log(str);
    }
    return str;
}