import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import PersonIcon from '@mui/icons-material/Person';
import { Device } from '../../../../store/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { grey } from '@mui/material/colors';

export default function IssuedDevice(props: { serialNumber: string }) {
    const [isIssued, setIsIssued] = React.useState(false);
    const issuedDevices: Device[] = useSelector(
        (state: RootState) => state.devices.issued
    );

    React.useEffect(() => {
        issuedDevices.some(el => {
            if(el.serialNumber === props.serialNumber) {
                setIsIssued(true);
            } else {
                setIsIssued(false);
            }
        })
    }, [])

    return (
        <>
            {isIssued ? ( 
            <Tooltip title="Urządzenie jest przypisane" arrow placement='right'>
                <PersonIcon color="primary"/>
            </Tooltip>) : (<Tooltip title="Urządzenie nie jest przypisane" arrow placement='right'>
                <PersonIcon style={{ color: grey[500] }}/>
            </Tooltip>)}
        </>
    );
}