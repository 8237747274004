import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from "react-redux";
import { editPatient } from '../../../../store/slices/patientsSlice';
import { AdminData, Patient } from '../../../../store/types';
import axios from "axios";
import { RootState } from '../../../../store/store';
import { Alert, Snackbar } from '@mui/material';

export default function EditDialog(props: { patient: Patient }) {
    const [open, setOpen] = React.useState(false);
    const [editOk, setEditOk] = React.useState(false);
    const [editNieOk, setEditNieOk] = React.useState(false);
    const [editedPatient, setEditedPatient] = React.useState(props.patient)
    const dispatch = useDispatch();
    const user: AdminData = useSelector(
        (state: RootState) => state.adminData.value
    );

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    const handleSubmit = () => {
        axios({
            method: 'post',
            url: `${user.url}/users/editUser`,
            data: { id: editedPatient.id, name: editedPatient.name, surname: editedPatient.surname, email: editedPatient.email},
            headers: { Authorization: `Bearer ${user.token}` },
            timeout: 8000
        })
            .then(response => {
                if(response.status === 200){
                    setOpen(false);
                    setEditOk(true);
                    dispatch(editPatient(editedPatient));
                }
            })
            .catch(error => { console.log(error); setEditNieOk(true) });
    }

    return (
        <>
            <IconButton onClick={() => { handleClickOpen() }}>
                <EditIcon />
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edycja</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Imię"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={editedPatient.name}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setEditedPatient({ ...editedPatient, name: event.target.value });
                        }}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="surname"
                        label="Nazwisko"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={editedPatient.surname}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setEditedPatient({ ...editedPatient, surname: event.target.value });
                        }}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="E-mail"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={editedPatient.email}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setEditedPatient({ ...editedPatient, email: event.target.value });
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        //dispatch(editPatient(editedPatient));
                        handleSubmit();
                    }}>Zatwierdź zmiany</Button>
                    <Button onClick={handleClose}>Anuluj</Button>
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={editOk} autoHideDuration={6000} onClose={() => { setEditOk(false) }}>
                <Alert onClose={() => { setEditOk(false) }} severity="success" sx={{ width: '100%' }}>
                    Pomyślnie dokonano zmian.
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={editNieOk} autoHideDuration={6000} onClose={() => { setEditNieOk(false) }}>
                <Alert onClose={() => { setEditNieOk(false) }} severity="error" sx={{ width: '100%' }}>
                    Wystąpił błąd podczad edycji.
                </Alert>
            </Snackbar>
        </>
    );
}