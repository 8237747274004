import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { deletePatient } from '../../../../store/slices/patientsSlice';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { AdminData } from '../../../../store/types';
import { RootState } from '../../../../store/store';

export default function DeleteDialog(props: { id: string, name: string, surname: string }) {
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const user: AdminData = useSelector(
        (state: RootState) => state.adminData.value
    );

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        axios({
            method: 'post',
            url: `${user.url}/users/deleteUser?` + new URLSearchParams({ id: props.id }).toString(),
            headers: { Authorization: `Bearer ${user.token}` },
            timeout: 8000
        })
            .then(response => {
                if(response.status === 200){
                    setOpen(false);
                }
                console.log(response);
            })
            .catch(error => { console.log(error) });
    }

    return (
        <>
            <IconButton onClick={handleClickOpen}>
                <DeleteIcon />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Potwierdzenie"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Czy na pewno chcesz usunąć użytkownika {props.name} {props.surname}? <br />
                        Nie będzie można cofnąć tej operacji.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { handleClose() }}>Wyjdź</Button>
                    <Button onClick={() => { handleSubmit() ; dispatch(deletePatient(props.id)); }} autoFocus>
                        Zatwierdź
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}